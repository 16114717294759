import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/website/website/src/templates/mdx-page.tsx";
import { Link } from "gatsby";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Terms & Privacy" mdxType="SEO" />
    <h1>{`Terms & Privacy`}</h1>
    <ul>
      <li parentName="ul">
        <Link to="/terms" mdxType="Link">Terms</Link>
      </li>
      <li parentName="ul">
        <Link to="/privacy" mdxType="Link">Privacy Policy</Link>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      